import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngxs/store';

import {
  SwitchCanUploadData,
  DeleteDocument,
  DownloadDocument,
  LoadUploadDocumentsInfo,
  UploadDocuments,
} from './documents.actions';
import { DocumentsSelectors } from '../selectors/documents.selectors';

import { BaseFileUploadErrors } from '../models/documents.model';

@Injectable({ providedIn: 'root' })
export class DocumentsStoreService {
  canUploadData: Signal<boolean>;

  constructor(private store: Store) {
    this.canUploadData = this.store.selectSignal(
      DocumentsSelectors.canUploadData,
    );
  }

  @Dispatch()
  switchCanUploadData = (canUploadData: boolean) =>
    new SwitchCanUploadData(canUploadData);

  @Dispatch()
  loadUploadDocumentsInfo = (
    uploadUrl: string,
    fileUploadErrors: BaseFileUploadErrors,
    fileUploadSuccess: string,
    auditId?: string,
  ) =>
    new LoadUploadDocumentsInfo(
      uploadUrl,
      fileUploadErrors,
      fileUploadSuccess,
      auditId,
    );

  @Dispatch()
  uploadDocuments = (files: File[]) => new UploadDocuments(files);

  @Dispatch()
  downloadDocument = (documentId: number, fileName?: string) =>
    new DownloadDocument(documentId, fileName);

  deleteDocument = (documentId: number) =>
    this.store.dispatch(new DeleteDocument(documentId));
}
