import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import {
  catchError,
  combineLatest,
  EMPTY,
  forkJoin,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import { Message, MessageService } from 'primeng/api';
import { TranslocoService } from '@jsverse/transloco';

import {
  downloadFromByteArray,
  FileUpload,
  FileUploadToastHelper,
  getToastContentBySeverity,
  ToastSeverity,
} from '@customer-portal/shared';

import { RouteStoreService } from '@customer-portal/router';

import {
  DeleteDocument,
  DeleteDocumentSuccesfully,
  DeleteDocumentWithError,
  DownloadDocument,
  DownloadDocumentSuccess,
  DownloadDocumentFail,
  ShowNotificationAfterUploadDocumentSuccess,
  ShowNotificationAfterUploadDocumentFail,
  UploadDocuments,
  SwitchCanUploadData,
  UploadDocumentsSuccess,
  LoadUploadDocumentsInfo,
} from './documents.actions';
import { DocumentsService } from '../services/documents.service';
import { BaseFileUploadErrors } from '../models/documents.model';

export interface DocumentsStateModel {
  canUploadData: boolean;
  auditId: string;
  uploadUrl: string;
  fileUploadErrors: BaseFileUploadErrors | null;
  fileUploadSuccess: string;
}

const defaultState: DocumentsStateModel = {
  canUploadData: false,
  auditId: '',
  uploadUrl: '',
  fileUploadErrors: null,
  fileUploadSuccess: '',
};

@State<DocumentsStateModel>({
  name: 'documents',
  defaults: defaultState,
})
@Injectable()
export class DocumentsState {
  private toastHelper: FileUploadToastHelper;

  constructor(
    private documentsService: DocumentsService,
    private routeStoreService: RouteStoreService,
    private messageService: MessageService,
    private ts: TranslocoService,
  ) {
    this.toastHelper = new FileUploadToastHelper(this.ts, this.messageService);
  }

  @Action(DownloadDocument)
  downloadDocument(
    ctx: StateContext<DocumentsStateModel>,
    { documentId, fileName }: DownloadDocument,
  ) {
    return this.routeStoreService.getQueryParamByKey('contactId').pipe(
      switchMap((contactId) =>
        this.documentsService
          .downloadDocument(contactId, documentId, fileName)
          .pipe(
            tap((data) => {
              if (!data) return;

              ctx.dispatch(new DownloadDocumentSuccess(data));
            }),
            catchError(() => ctx.dispatch(new DownloadDocumentFail())),
          ),
      ),
    );
  }

  @Action(DownloadDocumentSuccess)
  downloadDocumentSuccesfully(
    _: StateContext<DocumentsStateModel>,
    { blob }: DownloadDocumentSuccess,
  ) {
    const contentDisposition = blob.headers.get('content-disposition');

    if (!contentDisposition) return;

    const extractedFileName = this.extractFileName(contentDisposition);

    downloadFromByteArray(blob, extractedFileName);

    this.messageService.add(
      getToastContentBySeverity(ToastSeverity.DownloadSuccess),
    );
  }

  @Action(DownloadDocumentFail)
  downloadDocumentWithError() {
    this.showErrorMessage();
  }

  @Action(DeleteDocument)
  deleteDocument(
    ctx: StateContext<DocumentsStateModel>,
    { documentId }: DeleteDocument,
  ) {
    return this.documentsService.deleteDocument(documentId).pipe(
      tap((data) => {
        if (!data) return;

        if (data.isSuccess) {
          ctx.dispatch(new DeleteDocumentSuccesfully());
        } else {
          ctx.dispatch(new DeleteDocumentWithError());
        }
      }),
      catchError(() => ctx.dispatch(new DeleteDocumentWithError())),
    );
  }

  @Action(DeleteDocumentSuccesfully)
  deleteDocumentSuccesfully() {
    this.messageService.add(
      getToastContentBySeverity(ToastSeverity.DeleteSuccess),
    );
  }

  @Action(DeleteDocumentWithError)
  deleteDocumentWithError() {
    this.showErrorMessage();
  }

  @Action(SwitchCanUploadData)
  switchCanUploadData(
    ctx: StateContext<DocumentsStateModel>,
    { canUploadData }: SwitchCanUploadData,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      canUploadData,
    });
  }

  @Action(LoadUploadDocumentsInfo)
  loadUploadDocumentsInfo(
    ctx: StateContext<DocumentsStateModel>,
    {
      uploadUrl,
      fileUploadErrors,
      fileUploadSuccess,
      auditId,
    }: LoadUploadDocumentsInfo,
  ) {
    const state = ctx.getState();

    if (auditId) {
      ctx.patchState({
        ...state,
        uploadUrl,
        fileUploadErrors,
        fileUploadSuccess,
        auditId,
      });
    } else {
      ctx.patchState({
        ...state,
        uploadUrl,
        fileUploadErrors,
        fileUploadSuccess,
      });
    }
  }

  @Action(UploadDocuments)
  uploadDocuments(
    ctx: StateContext<DocumentsStateModel>,
    { files }: UploadDocuments,
  ): Observable<FileUpload[]> {
    return combineLatest([
      this.routeStoreService.getQueryParamByKey('contactId'),
      this.routeStoreService.getPathParamByKey('auditId'),
      this.routeStoreService.getPathParamByKey('findingId'),
    ]).pipe(
      switchMap(([contactId, auditId, findingId]) => {
        if (auditId) {
          ctx.patchState({
            auditId,
          });
        }

        const aId = ctx.getState().auditId;
        const { uploadUrl, fileUploadErrors, fileUploadSuccess } =
          ctx.getState();

        if (!files || !files.length || !uploadUrl) return EMPTY;

        const dataChecked = files.map((file) => {
          const newName = file.name.replace(/:/g, ';');

          return new File([file], newName, {
            type: file.type,
            lastModified: file.lastModified,
          });
        });

        return forkJoin(
          dataChecked.map((file) =>
            this.documentsService
              .uploadDocument(uploadUrl, file, contactId, aId, findingId)
              .pipe(
                tap((uploadStatus: FileUpload) => {
                  if (uploadStatus.isSuccess) {
                    ctx.dispatch(
                      new ShowNotificationAfterUploadDocumentSuccess(
                        uploadStatus,
                        fileUploadSuccess,
                      ),
                    );
                  } else {
                    ctx.dispatch(
                      new ShowNotificationAfterUploadDocumentFail(
                        uploadStatus,
                        fileUploadErrors!,
                      ),
                    );
                  }
                }),
              ),
          ),
        );
      }),
      tap((data: FileUpload[]) => {
        const state = ctx.getState();
        ctx.patchState({
          ...state,
          canUploadData: false,
        });
        const isAnyFileUploaded = data.some(
          (uploadStatus: FileUpload) => uploadStatus.isSuccess,
        );

        if (isAnyFileUploaded) {
          ctx.dispatch(new UploadDocumentsSuccess());
        }
      }),
    );
  }

  @Action(ShowNotificationAfterUploadDocumentSuccess)
  showNotificationAfterUploadDocumentSuccess(
    _: StateContext<DocumentsStateModel>,
    {
      uploadStatus,
      fileUploadSuccess,
    }: ShowNotificationAfterUploadDocumentSuccess,
  ) {
    const message: Message = getToastContentBySeverity(
      ToastSeverity.UploadSuccess,
    );
    message.summary = this.ts.translate(message.summary!);
    message.detail = this.ts.translate(fileUploadSuccess, {
      filename: uploadStatus.data.fileName,
    });

    this.messageService.add(message);
  }

  @Action(ShowNotificationAfterUploadDocumentFail)
  showNotificationAfterUploadDocumentFail(
    _: StateContext<DocumentsStateModel>,
    { uploadStatus, fileUploadErrors }: ShowNotificationAfterUploadDocumentFail,
  ) {
    const message: Message = getToastContentBySeverity(
      ToastSeverity.UploadError,
    );
    message.summary = this.ts.translate(message.summary!);
    const detail =
      fileUploadErrors[
        uploadStatus.error!.errorCode as keyof typeof fileUploadErrors
      ];

    message.detail = this.ts.translate(detail, {
      filename: uploadStatus.error!.fileName,
    });
    this.messageService.add(message);
  }

  private extractFileName(contentDisposition: string): string {
    return contentDisposition
      .split(';')
      .map((x) => x.trim())
      .filter((y) => y.startsWith('filename='))
      .map((z) => z.replace('filename=', '').replace(/"/g, ''))
      .reduce((z) => z);
  }

  private showErrorMessage() {
    this.messageService.add(getToastContentBySeverity(ToastSeverity.Error));
  }
}
